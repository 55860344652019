@import url(https://fonts.googleapis.com/css?family=Lato:300,400,900);
@charset "UTF-8";
* {
  box-sizing: border-box; }

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0; }

a {
  color: #00A2FF;
  text-decoration: none;
  position: relative; }
  a:after {
    content: "";
    display: block;
    position: absolute;
    width: 50px;
    height: 3px;
    bottom: -7px;
    left: 0;
    background: #00A2FF;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 250ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 250ms cubic-bezier(0.19, 1, 0.22, 1); }
  a:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.wrapper {
  max-width: 1660px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px; }
  @media screen and (max-width: 600px) {
    .wrapper {
      padding: 0 15px; } }

.rainbow {
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-name: rainbow;
          animation-name: rainbow;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.rainbow--alt {
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-name: rainbow-alt;
          animation-name: rainbow-alt; }

@-webkit-keyframes rainbow {
  0% {
    color: #00A2FF; }
  20% {
    color: #10C840; }
  40% {
    color: #FAC800; }
  60% {
    color: #D24040; }
  80% {
    color: #660999; }
  100% {
    color: #00A2FF; } }

@keyframes rainbow {
  0% {
    color: #00A2FF; }
  20% {
    color: #10C840; }
  40% {
    color: #FAC800; }
  60% {
    color: #D24040; }
  80% {
    color: #660999; }
  100% {
    color: #00A2FF; } }

@-webkit-keyframes rainbow-alt {
  0% {
    color: #10C840; }
  20% {
    color: #660999; }
  40% {
    color: #00A2FF; }
  60% {
    color: #D24040; }
  80% {
    color: #FAC800; }
  100% {
    color: #10C840; } }

@keyframes rainbow-alt {
  0% {
    color: #10C840; }
  20% {
    color: #660999; }
  40% {
    color: #00A2FF; }
  60% {
    color: #D24040; }
  80% {
    color: #FAC800; }
  100% {
    color: #10C840; } }

.wavy-text {
  white-space: nowrap; }
  .wavy-text:after {
    content: " "; }
  .wavy-text:before {
    content: " "; }

.wavy-text__letter {
  display: inline-block; }

.top-menu {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #660999;
  border: none;
  margin: 0; }

.top-menu__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; }

.top-menu__content {
  opacity: 0;
  color: #FFFFFF;
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 459px) {
    .top-menu__content {
      flex-direction: column; } }

.top-menu__social {
  display: flex;
  flex-direction: row;
  opacity: 0; }

.top-menu__social--hidden, .top-menu__content--hidden {
  display: none; }

.social-link {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: block; }
  .social-link:after {
    display: none; }
  .social-link:hover .social-link__icon {
    fill: #000000; }

.social-link__icon {
  width: 30px;
  fill: #FFFFFF; }

.top-menu__text-wrap {
  white-space: nowrap; }

.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.home__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: flex-start;
  padding: 0 30px; }

.home__title {
  margin: 0 0 15px 0;
  font-size: 72px;
  font-weight: 900; }
  @media screen and (min-width: 540px) and (max-width: 1600px) {
    .home__title {
      display: flex;
      flex-direction: column; } }
  @media screen and (min-width: 816px) and (max-width: 1200px) {
    .home__title {
      display: flex;
      flex-direction: column;
      font-size: 64px; } }
  @media screen and (min-width: 731px) and (max-width: 815px) {
    .home__title {
      font-size: 56px; } }
  @media screen and (min-width: 631px) and (max-width: 730px) {
    .home__title {
      font-size: 48px; } }
  @media screen and (min-width: 321px) and (max-width: 630px) {
    .home__title {
      font-size: 36px; } }
  @media screen and (max-width: 320px) {
    .home__title {
      font-size: 28px; } }

.home__subtitle {
  margin: 0 0 50px 0;
  font-size: 36px;
  font-weight: 400; }
  @media screen and (max-width: 1200px) {
    .home__subtitle {
      font-size: 28px; } }
  @media screen and (min-width: 731px) and (max-width: 815px) {
    .home__subtitle {
      font-size: 26px; } }
  @media screen and (min-width: 631px) and (max-width: 730px) {
    .home__subtitle {
      font-size: 24px; } }
  @media screen and (min-width: 321px) and (max-width: 630px) {
    .home__subtitle {
      font-size: 22px; } }
  @media screen and (max-width: 320px) {
    .home__subtitle {
      font-size: 20px; } }

.home__brief {
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 1200px) {
    .home__brief {
      font-size: 23px; } }
  @media screen and (min-width: 731px) and (max-width: 815px) {
    .home__brief {
      font-size: 22px; } }
  @media screen and (min-width: 631px) and (max-width: 730px) {
    .home__brief {
      font-size: 20px; } }
  @media screen and (min-width: 321px) and (max-width: 630px) {
    .home__brief {
      font-size: 18px; } }
  @media screen and (max-width: 320px) {
    .home__brief {
      font-size: 16px; } }

.home__contact {
  margin: 0 0 10px 0;
  font-size: 24px; }
  @media screen and (max-width: 1200px) {
    .home__contact {
      font-size: 23px; } }
  @media screen and (min-width: 731px) and (max-width: 815px) {
    .home__contact {
      font-size: 22px; } }
  @media screen and (min-width: 631px) and (max-width: 730px) {
    .home__contact {
      font-size: 20px; } }
  @media screen and (min-width: 321px) and (max-width: 630px) {
    .home__contact {
      font-size: 18px; } }
  @media screen and (max-width: 320px) {
    .home__contact {
      font-size: 16px; } }

.home__link {
  font-size: 24px;
  margin: 0 0 50px 0; }
  @media screen and (max-width: 1200px) {
    .home__link {
      font-size: 23px; } }
  @media screen and (min-width: 731px) and (max-width: 815px) {
    .home__link {
      font-size: 22px; } }
  @media screen and (min-width: 631px) and (max-width: 730px) {
    .home__link {
      font-size: 20px; } }
  @media screen and (max-width: 630px) {
    .home__link {
      font-size: 18px; } }

.home__dropdown {
  fill: #00A2FF;
  width: 50px;
  position: absolute;
  bottom: 50px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  @media screen and (max-width: 630px) {
    .home__dropdown {
      width: 30px;
      bottom: 20px; } }

.home__avatar {
  position: absolute;
  bottom: 10px;
  right: 25px;
  width: 143px;
  height: auto;
  -webkit-transform: rotateZ(10deg);
          transform: rotateZ(10deg); }
  @media screen and (max-width: 630px) {
    .home__avatar {
      opacity: 0.2;
      width: 100px; } }

.home__social {
  display: flex;
  flex-direction: row; }

.social-link__icon--black {
  fill: #000000; }

.social-link__icon--purple {
  fill: #660999; }

.social-link__icon--linkedin {
  fill: #0077B5; }

.social-link--big, .social-link__icon--big {
  width: 40px; }
  @media screen and (max-width: 630px) {
    .social-link--big, .social-link__icon--big {
      width: 30px; } }

.social-link--big {
  height: 40px; }
  @media screen and (max-width: 630px) {
    .social-link--big {
      height: 30px; } }

.social-link--margin-right {
  margin-left: 0;
  margin-right: 15px; }
  @media screen and (max-width: 630px) {
    .social-link--margin-right {
      margin-right: 10px; } }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); } }

@keyframes pulse {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px); } }

.clients {
  background: #101010;
  color: #FFFFFF;
  padding: 65px 0; }
  @media screen and (max-width: 600px) {
    .clients {
      padding: 25px 0; } }

.clients__header {
  color: #00A2FF;
  font-weight: 300;
  margin: 0 0 25px; }
  @media screen and (max-width: 600px) {
    .clients__header {
      text-align: center; } }

.clients__header--top-margin {
  margin-top: 75px; }
  @media screen and (max-width: 600px) {
    .clients__header--top-margin {
      margin-top: 45px; } }

.clients__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.client {
  background: #202020;
  box-shadow: 0 3px 5px 0 #000000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: calc(33.33% - 20px);
  padding: 30px;
  margin-bottom: 30px; }
  @media screen and (min-width: 981px) and (max-width: 1599px) {
    .client {
      flex-basis: calc(50% - 10px); } }
  @media screen and (max-width: 980px) {
    .client {
      flex-basis: 100%; } }
  @media screen and (max-width: 600px) {
    .client {
      padding: 15px;
      margin-bottom: 15px; } }

.client--last {
  margin: 0 auto 30px 0; }
  @media screen and (max-width: 600px) {
    .client--last {
      margin: 0 auto 15px 0; } }

.client--no-margin {
  margin-bottom: 0; }

.client__client {
  font-size: 32px;
  margin-bottom: 20px; }
  @media screen and (max-width: 600px) {
    .client__client {
      font-size: 28px; } }

.client__agency {
  opacity: 0.25;
  font-size: 24px;
  font-style: italic; }

.client__role {
  font-size: 24px;
  line-height: 32px; }
  @media screen and (max-width: 600px) {
    .client__role {
      font-size: 20px; } }

.client__role--spacer {
  margin-bottom: 25px; }

.client__stack {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 25px;
  color: #a6a6a6; }
  @media screen and (max-width: 600px) {
    .client__stack {
      font-size: 16px;
      margin-bottom: 20px; } }

.client__description {
  margin: 0;
  line-height: 24px;
  color: #a6a6a6; }
  @media screen and (max-width: 600px) {
    .client__description {
      font-size: 14px;
      line-height: 22px; } }

.client__description__link {
  color: #00A2FF;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto; }
  .client__description__link:after {
    display: none; }
  .client__description__link:hover {
    text-decoration: underline; }

.client__responsabilities {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0; }
  @media screen and (max-width: 600px) {
    .client__responsabilities {
      margin: 0 0 20px 0; } }

.responsabilities__item {
  padding-left: 30px;
  line-height: 20px;
  position: relative; }
  @media screen and (max-width: 600px) {
    .responsabilities__item {
      font-size: 14px;
      line-height: 16px; } }
  .responsabilities__item:not(:last-child) {
    margin-bottom: 10px; }
  .responsabilities__item:before {
    content: "👉";
    position: absolute;
    top: -2px;
    margin-right: 5px;
    left: 0; }

.client__link {
  margin-top: 30px; }
  @media screen and (max-width: 600px) {
    .client__link {
      margin-top: 20px;
      font-size: 14px;
      line-height: 16px; } }
  .client__link:before {
    content: '';
    width: 7px;
    height: 12px;
    background-color: transparent;
    background-image: url("/images/arrow.svg");
    background-size: 7px 12px;
    position: relative;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    display: inline-block;
    top: 1px;
    margin-right: 10px; }
  .client__link:after {
    left: 18px;
    height: 2px; }

.client__description__strikethrough {
  text-decoration: line-through; }

.client__note {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  line-height: 28px; }

.client__logo {
  max-height: 150px;
  max-width: 160px;
  margin: 20px; }

.clients__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.about {
  padding: 50px 0 65px;
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 600px) {
    .about {
      padding: 25px 0; } }

.about__lists {
  display: flex;
  flex-direction: row; }

.about__title {
  margin: 0 0 25px 0;
  font-size: 36px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .about__title {
      font-size: 26px; } }

.about__separator {
  height: 4px;
  background: #660999;
  border: none;
  border-radius: 2px;
  margin: 50px 0; }
  @media screen and (max-width: 600px) {
    .about__separator {
      margin: 25px 0; } }

.about__content {
  margin: 0 0 25px 0;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300; }
  @media screen and (max-width: 600px) {
    .about__content {
      font-size: 18px;
      line-height: 26px; } }

@media screen and (max-width: 600px) {
  .about__content--margin {
    margin-bottom: 30px; } }

.about__list {
  flex-basis: 50%;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 32px; }

.about__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 35px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 10px; }
  .about__items:last-child {
    margin-bottom: 0; }

.about__item {
  color: #202020;
  border-radius: 5px;
  width: 33%;
  text-align: left;
  padding: 10px 15px;
  font-weight: 300;
  font-size: 24px; }
  @media screen and (min-width: 570px) and (max-width: 799px) {
    .about__item {
      width: 50%; } }
  @media screen and (max-width: 569px) {
    .about__item {
      width: 100%;
      text-align: center; } }
  @media screen and (max-width: 600px) {
    .about__item {
      font-size: 20px; } }

.about__subheader {
  color: #00A2FF;
  font-weight: 300; }

.main-footer {
  background: #101010;
  color: #FFFFFF;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 1241px) {
    .main-footer {
      padding-top: 30px; } }

.main-footer__container {
  display: flex; }
  @media screen and (max-width: 1240px) {
    .main-footer__container {
      flex-direction: column;
      padding: 0; } }

.main-footer__title {
  margin: 0 0 15px 0;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .main-footer__title {
      font-size: 18px;
      line-height: 28px; } }

.main-footer__link {
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 35px 0; }
  @media screen and (max-width: 600px) {
    .main-footer__link {
      font-size: 18px;
      line-height: 22px; } }

.main-footer__cta {
  background: #202020;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px; }
  @media screen and (min-width: 1241px) {
    .main-footer__cta {
      padding: 0 30px 30px 0;
      width: 55%;
      max-width: 675px; } }
  .main-footer__cta:before {
    display: block;
    background: #202020;
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: -30px;
    left: 0; }
    @media screen and (max-width: 1240px) {
      .main-footer__cta:before {
        display: none; } }
  .main-footer__cta:after {
    display: block;
    background: #202020;
    content: '';
    width: 205px;
    height: 65px;
    position: absolute;
    top: -30px;
    right: -205px;
    -webkit-transform: rotateZ(70deg);
            transform: rotateZ(70deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    z-index: -1; }
    @media screen and (max-width: 1240px) {
      .main-footer__cta:after {
        display: none; } }

.main-footer__extend {
  position: absolute;
  background: #202020;
  left: 0;
  bottom: 0;
  display: block;
  content: '';
  height: calc(100% + 30px);
  width: 33%;
  z-index: 0; }
  @media screen and (max-width: 1240px) {
    .main-footer__extend {
      display: none; } }

.main-footer__extend--white {
  width: 66%;
  left: auto;
  bottom: auto;
  right: 0;
  top: 0;
  height: 73px;
  background: #FFFFFF; }

.main-footer__copy {
  padding: 30px 0;
  margin: auto 0 0 auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column; }
  @media screen and (max-width: 1240px) {
    .main-footer__copy {
      padding: 30px; } }
  @media screen and (max-width: 600px) {
    .main-footer__copy {
      margin: 0;
      align-items: center;
      text-align: center; } }

.main-footer__social {
  display: flex; }

.main-footer__copyright {
  margin-bottom: 5px; }

.main-footer__link {
  margin-bottom: 25px; }

.main-footer__small-link {
  white-space: nowrap;
  line-height: 24px; }
  .main-footer__small-link:hover {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto; }
    .main-footer__small-link:hover:after {
      display: none; }

.social-link--small {
  width: 25px;
  height: 25px;
  margin-right: 10px; }

.social-link__icon--small {
  width: 25px;
  height: 25px; }

.main-footer__separator {
  margin: 0 5px;
  padding-top: 3px; }
  @media screen and (max-width: 600px) {
    .main-footer__separator {
      display: none; } }

.main-footer__creds {
  display: flex; }
  @media screen and (max-width: 600px) {
    .main-footer__creds {
      flex-direction: column; } }

