.home {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: flex-start;
	padding: 0 30px;
}

.home__title {
	margin: 0 0 15px 0;
	font-size: 72px;
	font-weight: $weightBlack;
	@media screen and (min-width: 540px) and (max-width: 1600px) {
		display: flex;
		flex-direction: column;
	}
	@media screen and (min-width: 816px) and (max-width: 1200px) {
		display: flex;
		flex-direction: column;
		font-size: 64px;
	}
	@media screen and (min-width: 731px) and (max-width: 815px) {
		font-size: 56px;
	}
	@media screen and (min-width: 631px) and (max-width: 730px) {
		font-size: 48px;
	}
	@media screen and (min-width: 321px) and (max-width: 630px) {
		font-size: 36px;
	}
	@media screen and (max-width: 320px) {
		font-size: 28px;
	}
}

.home__subtitle {
	margin: 0 0 50px 0;
	font-size: 36px;
	font-weight: $weightRegular;
	@media screen and (max-width: 1200px){
		font-size: 28px;
	}
	@media screen and (min-width: 731px) and (max-width: 815px) {
		font-size: 26px;
	}
	@media screen and (min-width: 631px) and (max-width: 730px) {
		font-size: 24px;
	}
	@media screen and (min-width: 321px) and (max-width: 630px) {
		font-size: 22px;
	}
	@media screen and (max-width: 320px) {
		font-size: 20px;
	}
}

.home__brief {
	margin: 0 0 10px 0;
	font-size: 24px;
	font-weight: $weightRegular;
	@media screen and (max-width: 1200px){
		font-size: 23px;
	}
	@media screen and (min-width: 731px) and (max-width: 815px) {
		font-size: 22px;
	}
	@media screen and (min-width: 631px) and (max-width: 730px) {
		font-size: 20px;
	}
	@media screen and (min-width: 321px) and (max-width: 630px) {
		font-size: 18px;
	}
	@media screen and (max-width: 320px) {
		font-size: 16px;
	}
}

.home__contact {
	margin: 0 0 10px 0;
	font-size: 24px;
	@media screen and (max-width: 1200px){
		font-size: 23px;
	}
	@media screen and (min-width: 731px) and (max-width: 815px) {
		font-size: 22px;
	}
	@media screen and (min-width: 631px) and (max-width: 730px) {
		font-size: 20px;
	}
	@media screen and (min-width: 321px) and (max-width: 630px) {
		font-size: 18px;
	}
	@media screen and (max-width: 320px) {
		font-size: 16px;
	}
}

.home__link {
	font-size: 24px;
	margin: 0 0 50px 0;
	@media screen and (max-width: 1200px){
		font-size: 23px;
	}
	@media screen and (min-width: 731px) and (max-width: 815px) {
		font-size: 22px;
	}
	@media screen and (min-width: 631px) and (max-width: 730px) {
		font-size: 20px;
	}
	@media screen and (max-width: 630px) {
		font-size: 18px;
	}
}

.home__dropdown {
	fill: $blue;
	width: 50px;
	position: absolute;
	bottom: 50px;
	animation-duration: 1s;
	animation-name: pulse;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	@media screen and (max-width: 630px) {
		width: 30px;
		bottom: 20px;
	}
}

.home__avatar {
	position: absolute;
	bottom: 10px;
	right: 25px;
	width: 143px;
	height: auto;
	transform: rotateZ(10deg);
	@media screen and (max-width: 630px) {
		opacity: 0.2;
		width: 100px;
	}
}

.home__social {
	display: flex;
	flex-direction: row;
}

.social-link__icon--black {
	fill: $black;
}

.social-link__icon--purple {
	fill: $purple;
}

.social-link__icon--linkedin {
	fill: #0077B5;
}

.social-link--big, .social-link__icon--big {
	width: 40px;
	@media screen and (max-width: 630px) {
		width: 30px;
	}
}

.social-link--big {
	height: 40px;
	@media screen and (max-width: 630px) {
		height: 30px;
	}
}

.social-link--margin-right {
	margin-left: 0;
	margin-right: 15px;
	@media screen and (max-width: 630px) {
		margin-right: 10px;
	}
}

@keyframes pulse {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-25px);
	}
}
