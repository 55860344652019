.about {
	padding: 50px 0 65px;
	max-width: 800px;
	margin: 0 auto;
	@media screen and (max-width: 600px) {
		padding: 25px 0;
	}
}

.about__lists {
	display: flex;
	flex-direction: row;
}

.about__title {
	margin: 0 0 25px 0;
	font-size: 36px;
	font-weight: $weightRegular;
	@media screen and (max-width: 600px) {
		font-size: 26px;
	}
}

.about__separator {
	height: 4px;
	background: $purple;
	border: none;
	border-radius: 2px;
	margin: 50px 0;
	@media screen and (max-width: 600px) {
		margin: 25px 0;
	}
}

.about__content {
	margin: 0 0 25px 0;
	font-size: 22px;
	line-height: 32px;
	font-weight: $weightLight;
	@media screen and (max-width: 600px) {
		font-size: 18px;
		line-height: 26px;
	}
}

.about__content--margin {
	@media screen and (max-width: 600px) {
		margin-bottom: 30px;
	}
}

.about__list {
	flex-basis: 50%;
	font-size: 22px;
	margin-bottom: 0;
	line-height: 32px;
}

.about__items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 35px;
	box-shadow: 0 1px 5px 0 rgba($black, 0.15);
	padding: 10px;
	&:last-child {
		margin-bottom: 0;
	}
}

.about__item {
	// background: #202020;
	// color: #FFF;
	color: #202020;
	border-radius: 5px;
	width: 33%;
	text-align: left;
	padding: 10px 15px;
	font-weight: $weightLight;
	font-size: 24px;
	@media screen and (min-width: 570px) and (max-width: 799px) {
		width: 50%;
	}
	@media screen and (max-width: 569px) {
		width: 100%;
		text-align: center;
	}
	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
}

.about__subheader {
	color: $blue;
	font-weight: $weightLight;
}
